import React, { useState, useEffect, useRef, useCallback, ReactElement } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AddIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import PropagateLoader  from "react-spinners/PropagateLoader";
// import Markdown from 'react-markdown';
import { MuiMarkdown as Markdown } from "mui-markdown";
import './App.css';
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const welcomeMarkdown = `
# Welcome to Ketr-Chat.

This LLM agent was built by James Ketrenos in order to provide answers to any questions you may have about his work history.

In addition to being a RAG enabled expert system, the LLM is configured with real-time access to weather, stocks, the current time, and can answer questions about the contents of a website.

Ask things like:
  * What are the headlines from CNBC?
  * What is the weather in Portland, OR?
  * What is James Ketrenos' work history?
  * What are the stock value of the most traded companies?
  * What programming languages has James used?
`;

const welcomeMessage = {
  "role": "assistant", "content": welcomeMarkdown
};
const loadingMessage = { "role": "assistant", "content": "Instancing chat session..." };

type Tool = {
  type: string,
  function?: {
    name: string,
    description: string,
    parameters?: any,
    returns?: any
  },
  name?: string,
  description?: string,
  enabled: boolean
};

type SeverityType = 'error' | 'info' | 'success' | 'warning' | undefined;

interface ControlsParams {
  tools: Tool[],
  rags: Tool[],
  systemPrompt: string,
  systemInfo: SystemInfo,
  toggleTool: (tool: Tool) => void,
  toggleRag: (tool: Tool) => void,
  setSystemPrompt: (prompt: string) => void,
  reset: (types: ("rags" | "tools" | "history" | "system-prompt")[], message: string) => Promise<void>
};

type GPUInfo = {
  name: string,
  memory: number,
  discrete: boolean
}
type SystemInfo = {
  "Installed RAM": string,
  "Graphics Card": GPUInfo[],
  "CPU": string
};

type MessageMetadata = {
  rag: any,
  tools: any[]
};

type MessageData = {
  role: string,
  content: string,
  user?: string,
  type?: string,
  id?: string,
  isProcessing?: boolean,
  metadata?: MessageMetadata
};

type MessageList = MessageData[];


const getConnectionBase = (loc: any): string => {
  if (!loc.host.match(/.*battle-linux.*/)) {
    return loc.protocol + "//" + loc.host;
  } else {
    return loc.protocol + "//battle-linux.ketrenos.com:5000";
  }
}

const SystemInfoComponent: React.FC<{ systemInfo: SystemInfo }> = ({ systemInfo }) => {
  const [systemElements, setSystemElements] = useState<ReactElement[]>([]);

  const convertToSymbols = (text: string) => {
    return text
      .replace(/\(R\)/g, '®')  // Replace (R) with the ® symbol
      .replace(/\(C\)/g, '©')  // Replace (C) with the © symbol
      .replace(/\(TM\)/g, '™'); // Replace (TM) with the ™ symbol
  };

  useEffect(() => {
    const elements = Object.entries(systemInfo).flatMap(([k, v]) => {
      // If v is an array, repeat for each card
      if (Array.isArray(v)) {
        return v.map((card, index) => (
          <div key={index} className="SystemInfoItem">
            <div>{convertToSymbols(k)} {index}</div>
            <div>{convertToSymbols(card.name)} {card.discrete ? `w/ ${Math.round(card.memory / (1024 * 1024 * 1024))}GB RAM` : "(integrated)"}</div>
          </div>
        ));
      }

      // If it's not an array, handle normally
      return (
        <div key={k} className="SystemInfoItem">
          <div>{convertToSymbols(k)}</div>
          <div>{convertToSymbols(String(v))}</div>
        </div>
      );
    });

    setSystemElements(elements);
  }, [systemInfo]);

  return <div className="SystemInfo">{systemElements}</div>;
};

const Controls = ({ tools, rags, systemPrompt, toggleTool, toggleRag, setSystemPrompt, reset, systemInfo }: ControlsParams) => {
  const [editSystemPrompt, setEditSystemPrompt] = useState<string>(systemPrompt);

  useEffect(() => {
    setEditSystemPrompt(systemPrompt);
  }, [systemPrompt, setEditSystemPrompt]);

  const toggle = async (type: string, index: number) => {
    switch (type) {
      case "rag":
        toggleRag(rags[index])
        break;
      case "tool":
        toggleTool(tools[index]);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && event.ctrlKey) {
      switch (event.target.id) {
        case 'SystemPromptInput':
          setSystemPrompt(editSystemPrompt);
          break;
      }
    }
  };

  return (<div className="Controls">
    <Typography component="span" sx={{ mb: 1 }}>
      You can change the information available to the LLM by adjusting the following settings:

    </Typography>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="span">System Prompt</Typography>
      </AccordionSummary>
      <AccordionActions style={{ flexDirection: "column" }}>
        <TextField
          variant="outlined"
          autoFocus
          fullWidth
          multiline
          type="text"
          value={editSystemPrompt}
          onChange={(e) => setEditSystemPrompt(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Enter the new system prompt.."
          id="SystemPromptInput"
        />
        <div style={{ display: "flex", flexDirection: "row", gap: "8px", paddingTop: "8px" }}>
          <Button variant="contained" disabled={editSystemPrompt === systemPrompt} onClick={() => { setSystemPrompt(editSystemPrompt); }}>Set</Button>
          <Button variant="outlined" onClick={() => { reset(["system-prompt"], "System prompt reset."); }} color="error">Reset</Button>
        </div>
      </AccordionActions>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="span">Tools</Typography>
      </AccordionSummary>
      <AccordionDetails>
        These tools can be made available to the LLM for obtaining real-time information from the Internet. The description provided to the LLM is provided for reference.
      </AccordionDetails>
      <AccordionActions>
        <FormGroup sx={{ p: 1 }}>
          {
            tools.map((tool, index) =>
              <Box key={index}>
                <Divider />
                <FormControlLabel control={<Switch checked={tool.enabled} />} onChange={() => toggle("tool", index)} label={tool?.function?.name} />
                <Typography>{tool?.function?.description}</Typography>
              </Box>
            )
          }</FormGroup>
      </AccordionActions>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="span">RAG</Typography>
      </AccordionSummary>
      <AccordionDetails>
        These RAG databases can be enabled / disabled for adding additional context based on the chat request.
      </AccordionDetails>
      <AccordionActions>
        <FormGroup sx={{ p: 1 }}>
          {
            rags.map((rag, index) =>
              <Box key={index}>
                <Divider />
                <FormControlLabel control={<Switch checked={rag.enabled} />} onChange={() => toggle("rag", index)} label={rag?.name} />
                <Typography>{rag?.description}</Typography>
              </Box>
            )
          }</FormGroup>
      </AccordionActions>
    </Accordion>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="span">System Information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        The server is running on the following hardware:
      </AccordionDetails>
      <AccordionActions>
        <SystemInfoComponent systemInfo={systemInfo} />
      </AccordionActions>
    </Accordion>
    <Button onClick={() => { reset(["history"], "History cleared."); }}>Clear Chat History</Button>
    <Button onClick={() => { reset(["rags", "tools", "system-prompt"], "Default settings restored.") }}>Reset to defaults</Button>
  </div>);
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

interface MessageInterface {
  message: MessageData
};

interface MessageMetaInterface {
  metadata: MessageMetadata
}
const MessageMeta = ({ metadata }: MessageMetaInterface) => {
  if (metadata === undefined) {
    return <></>
  }

  return (<>
    {
      metadata.tools !== undefined && metadata.tools.length !== 0 &&
      <Typography sx={{ marginBottom: 2 }}>
        <p>Tools queried:</p>
        {metadata.tools.map((tool: any, index: number) => <>
          <Divider />
          <Box sx={{ fontSize: "0.75rem", display: "flex", flexDirection: "column", mb: 0.5, mt: 0.5 }} key={index}>
            <div style={{ display: "flex", flexDirection: "column", paddingRight: "1rem", minWidth: "10rem" }}>
              <div style={{ whiteSpace: "nowrap" }}>{tool.tool}</div>
              <div style={{ whiteSpace: "nowrap" }}>Result Len: {JSON.stringify(tool.result).length}</div>
            </div>
            <div style={{ display: "flex", padding: "3px", whiteSpace: "pre-wrap", flexGrow: 1, border: "1px solid #E0E0E0", maxHeight: "5rem", overflow: "auto" }}>{JSON.stringify(tool.result, null, 2)}</div>
          </Box>
        </>)}
      </Typography>
    }
    {
      metadata.rag.name !== undefined &&
      <Typography sx={{ marginBottom: 2 }}>
          <p>Top RAG {metadata.rag.ids.length} matches from '{metadata.rag.name}' collection against embedding vector of {metadata.rag.query_embedding.length} dimensions:</p>
        {metadata.rag.ids.map((id: number, index: number) => <>
          <Divider />
          <Box sx={{ fontSize: "0.75rem", display: "flex", flexDirection: "row", mb: 0.5, mt: 0.5 }} key={index}>
            <div style={{ display: "flex", flexDirection: "column", paddingRight: "1rem", minWidth: "10rem" }}>
              <div style={{ whiteSpace: "nowrap" }}>Doc ID: {metadata.rag.ids[index]}</div>
              <div style={{ whiteSpace: "nowrap" }}>Similarity: {Math.round(metadata.rag.distances[index] * 100) / 100}</div>
              <div style={{ whiteSpace: "nowrap" }}>Type: {metadata.rag.metadatas[index].doc_type}</div>
              <div style={{ whiteSpace: "nowrap" }}>Chunk Len: {metadata.rag.documents[index].length}</div>
            </div>
            <div style={{ display: "flex", padding: "3px", flexGrow: 1, border: "1px solid #E0E0E0", maxHeight: "5rem", overflow: "auto" }}>{metadata.rag.documents[index]}</div>
          </Box>
        </>
        )}
      </Typography >
    }
  </>
  );
};

const Message = ({ message }: MessageInterface) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formattedContent = message.content.trim();

  return (
    <Card sx={{ flexGrow: 1, pb: message.metadata ? 0 : "8px" }} className={(message.role === 'user' ? 'user-message' : 'assistant-message')}>
      <CardContent>
        {message.role === 'assistant' ?
          <Markdown children={formattedContent} />
          :
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {message.content}
          </Typography>
        }
      </CardContent>
      {message.metadata && <>
        <CardActions disableSpacing>
          <Typography sx={{ color: "darkgrey", p: 1, textAlign: "end", flexGrow: 1 }}>LLM information for this query</Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <MessageMeta metadata={message.metadata} />
          </CardContent>
        </Collapse>
      </>}
    </Card>
  );
}

type ContextStatus = {
  context_used: number,
  max_context: number
};

const App = () => {
  const [query, setQuery] = useState('');
  const [conversation, setConversation] = useState<MessageList>([]);
  const conversationRef = useRef<any>(null);
  const [processing, setProcessing] = useState(false);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [loc,] = useState<Location>(window.location)
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState<SeverityType>("success");
  const [tools, setTools] = useState<Tool[]>([]);
  const [rags, setRags] = useState<Tool[]>([]);
  const [systemPrompt, setSystemPrompt] = useState<string>("");
  const [serverSystemPrompt, setServerSystemPrompt] = useState<string>("");
  const [systemInfo, setSystemInfo] = useState<SystemInfo | undefined>(undefined);
  const [contextStatus, setContextStatus] = useState<ContextStatus>({ context_used: 0, max_context: 0 });

  // Scroll to bottom of conversation when conversation updates
  useEffect(() => {
    const queryElement = document.getElementById('QueryInput');
    if (queryElement) {
      queryElement.scrollIntoView();
    }
  }, [conversation]);

  // Set the snack pop-up and open it
  const setSnack = useCallback((message: string, severity: SeverityType = "success") => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackOpen(true);
  }, []);

  // Get the system information
  useEffect(() => {
    if (systemInfo !== undefined || sessionId === undefined) {
      return;
    }
    fetch(getConnectionBase(loc) + `/api/system-info/${sessionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setSystemInfo(data);
      })
      .catch(error => {
        console.error('Error obtaining system information:', error);
        setSnack("Unable to obtain system information.", "error");
      });
  }, [systemInfo, setSystemInfo, loc, setSnack, sessionId])

  const updateContextStatus = useCallback(() => {
    fetch(getConnectionBase(loc) + `/api/context-status/${sessionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log(`Session id: ${sessionId} -- history returned from server with ${data.length} entries`)
        setContextStatus(data);
      })
      .catch(error => {
        console.error('Error getting context status:', error);
        setSnack("Unable to obtain context status.", "error");
      });
  }, [setContextStatus, loc, setSnack, sessionId]);

  // Set the initial chat history to "loading" or the welcome message if loaded.
  useEffect(() => {
    if (sessionId === undefined) {
      setConversation([loadingMessage]);
    } else {
      fetch(getConnectionBase(loc) + `/api/history/${sessionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(`Session id: ${sessionId} -- history returned from server with ${data.length} entries`)
          setConversation([
            welcomeMessage,
            ...data
          ]);
        })
        .catch(error => {
          console.error('Error generating session ID:', error);
          setSnack("Unable to obtain chat history.", "error");
        });
      updateContextStatus();
    }
  }, [sessionId, setConversation, updateContextStatus, loc, setSnack]);

  // Extract the sessionId from the URL if present, otherwise
  // request a sessionId from the server.
  useEffect(() => {
    const url = new URL(loc.href);
    const pathParts = url.pathname.split('/').filter(Boolean);

    if (!pathParts.length) {
      console.log("No session id -- creating a new session")
      fetch(getConnectionBase(loc) + `/api/context`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log(`Session id: ${data.id} -- returned from server`)
          setSessionId(data.id);
          window.history.replaceState({}, '', `/${data.id}`);
        })
        .catch(error => console.error('Error generating session ID:', error));
    } else {
      console.log(`Session id: ${pathParts[0]} -- existing session`)
      setSessionId(pathParts[0]);
    }

  }, [setSessionId, loc]);

  // If the systemPrompt has not been set, fetch it from the server
  useEffect(() => {
    if (serverSystemPrompt !== "" || sessionId === undefined) {
      return;
    }
    const fetchSystemPrompt = async () => {
      // Make the fetch request with proper headers
      const response = await fetch(getConnectionBase(loc) + `/api/system-prompt/${sessionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
      const data = await response.json();
      const serverSystemPrompt = data["system-prompt"].trim();
      console.log("System prompt initialized to:", serverSystemPrompt);
      setServerSystemPrompt(serverSystemPrompt);
      setSystemPrompt(serverSystemPrompt);
    }

    fetchSystemPrompt();
  }, [sessionId, serverSystemPrompt, setServerSystemPrompt, loc]);

  // If the tools have not been set, fetch them from the server
  useEffect(() => {
    if (tools.length || sessionId === undefined) {
      return;
    }
    const fetchTools = async () => {
      try {
        // Make the fetch request with proper headers
        const response = await fetch(getConnectionBase(loc) + `/api/tools/${sessionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (!response.ok) {
          throw Error();
        }
        const tools = await response.json();
        setTools(tools);
      } catch (error: any) {
        setSnack("Unable to fetch tools", "error");
        console.error(error);
      }
    }

    fetchTools();
  }, [sessionId, tools, setTools, setSnack, loc]);

  // If the RAGs have not been set, fetch them from the server
  useEffect(() => {
    if (rags.length || sessionId === undefined) {
      return;
    }
    const fetchRags = async () => {
      try {
        // Make the fetch request with proper headers
        const response = await fetch(getConnectionBase(loc) + `/api/rags/${sessionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        if (!response.ok) {
          throw Error();
        }
        const rags = await response.json();
        setRags(rags);
      } catch (error: any) {
        setSnack("Unable to fetch RAGs", "error");
        console.error(error);
      }
    }

    fetchRags();
  }, [sessionId, rags, setRags, setSnack, loc]);

  const toggleRag = async (tool: Tool) => {
    tool.enabled = !tool.enabled
    try {
      const response = await fetch(getConnectionBase(loc) + `/api/rags/${sessionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ "tool": tool?.name, "enabled": tool.enabled }),
      });

      const rags = await response.json();
      setRags([...rags])
      setSnack(`${tool?.name} ${tool.enabled ? "enabled" : "disabled"}`);
    } catch (error) {
      console.error('Fetch error:', error);
      setSnack(`${tool?.name} ${tool.enabled ? "enabling" : "disabling"} failed.`, "error");
      tool.enabled = !tool.enabled
    }
  };

  const toggleTool = async (tool: Tool) => {
    tool.enabled = !tool.enabled
    try {
      const response = await fetch(getConnectionBase(loc) + `/api/tools/${sessionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ "tool": tool?.function?.name, "enabled": tool.enabled }),
      });

      const tools = await response.json();
      setTools([...tools])
      setSnack(`${tool?.function?.name} ${tool.enabled ? "enabled" : "disabled"}`);
    } catch (error) {
      console.error('Fetch error:', error);
      setSnack(`${tool?.function?.name} ${tool.enabled ? "enabling" : "disabling"} failed.`, "error");
      tool.enabled = !tool.enabled
    }
  };

  useEffect(() => {
    if (systemPrompt === serverSystemPrompt || !systemPrompt.trim() || sessionId === undefined) {
      return;
    }
    const sendSystemPrompt = async (prompt: string) => {
      try {
        const response = await fetch(getConnectionBase(loc) + `/api/system-prompt/${sessionId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ "system-prompt": prompt }),
        });

        const data = await response.json();
        const newPrompt = data["system-prompt"];
        if (newPrompt !== serverSystemPrompt) {
          setServerSystemPrompt(newPrompt);
          setSystemPrompt(newPrompt)
          setSnack("System prompt updated", "success");
        }
      } catch (error) {
        console.error('Fetch error:', error);
        setSnack("System prompt update failed", "error");
      }
    };

    sendSystemPrompt(systemPrompt);

  }, [systemPrompt, setServerSystemPrompt, serverSystemPrompt, loc, sessionId, setSnack]);

  const reset = async (types: ("rags" | "tools" | "history" | "system-prompt")[], message: string = "Update successful.") => {
    try {
      const response = await fetch(getConnectionBase(loc) + `/api/reset/${sessionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ "reset": types }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          throw Error()
        }
        for (const [key, value] of Object.entries(data)) {
          switch (key) {
            case "rags":
              setRags(value as Tool[]);
              break;
            case "tools":
              setTools(value as Tool[]);
              break;
            case "system-prompt":
              setServerSystemPrompt((value as any)["system-prompt"].trim());
              setSystemPrompt((value as any)["system-prompt"].trim());
              break;
            case "history":
              setConversation([welcomeMessage]);
              break;
          }
        }
        setSnack(message, "success");
      } else {
        throw Error(`${{ status: response.status, message: response.statusText }}`);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setSnack("Unable to restore defaults", "error");
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <>
      {sessionId !== undefined && systemInfo !== undefined && <Controls {...{ tools, rags, reset, systemPrompt, toggleTool, toggleRag, setSystemPrompt, systemInfo }} />}
    </>
  );

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      switch (event.target.id) {
        case 'QueryInput':
          sendQuery();
          break;
      }
    }
  };

  const onNew = async () => {
    reset(["history"], "New chat started.");
  }

  const sendQuery = async () => {
    if (!query.trim()) return;

    setSnack("Query sent", "info");

    const userMessage = [{ role: 'user', content: query }];

    // Add user message to conversation
    const newConversation: MessageList = [
      ...conversation,
      ...userMessage
    ];
    setConversation(newConversation);

    // Clear input
    setQuery('');
    setTimeout(() => {
      document.getElementById("QueryIput")?.focus();
    }, 1000);

    try {
      setProcessing(true);

      // Create a unique ID for the processing message
      const processingId = Date.now().toString();

      // Add initial processing message
      setConversation(prev => [
        ...prev,
        { role: 'assistant', content: 'Processing request...', id: processingId, isProcessing: true }
      ]);

      // Make the fetch request with proper headers
      const response = await fetch(getConnectionBase(loc) + `/api/chat/${sessionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ role: 'user', content: query.trim() }),
      });

      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      if (!response.body) {
        throw new Error('Response body is null');
      }

      // Set up stream processing with explicit chunking
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const chunk = decoder.decode(value, { stream: true });

        // Process each complete line immediately
        buffer += chunk;
        let lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep incomplete line in buffer
        for (const line of lines) {
          if (!line.trim()) continue;

          try {
            const update = JSON.parse(line);

            // Force an immediate state update based on the message type
            if (update.status === 'processing') {
              // Update processing message with immediate re-render
              setConversation(prev => prev.map(msg =>
                msg.id === processingId
                  ? { ...msg, content: update.message }
                  : msg
              ));

              // Add a small delay to ensure React has time to update the UI
              await new Promise(resolve => setTimeout(resolve, 0));

            } else if (update.status === 'done') {
              // Replace processing message with final result
              setConversation(prev => [
                ...prev.filter(msg => msg.id !== processingId),
                update.message
              ]);
              updateContextStatus();
            } else if (update.status === 'error') {
              // Show error
              setConversation(prev => [
                ...prev.filter(msg => msg.id !== processingId),
                { role: 'assistant', type: 'error', content: update.message }
              ]);
            }
          } catch (e) {
            setSnack("Error processing query", "error")
            console.error('Error parsing JSON:', e, line);
          }
        }
      }

      // Process any remaining buffer content
      if (buffer.trim()) {
        try {
          const update = JSON.parse(buffer);

          if (update.status === 'done') {
            setConversation(prev => [
              ...prev.filter(msg => msg.id !== processingId),
              update.message
            ]);
          }
        } catch (e) {
          setSnack("Error processing query", "error")
        }
      }

      setProcessing(false);
    } catch (error) {
      console.error('Fetch error:', error);
      setSnack("Unable to process query", "error");
      setConversation(prev => [
        ...prev.filter(msg => !msg.isProcessing),
        { role: 'assistant', type: 'error', content: `Error: ${error}` }
      ]);
      setProcessing(false);
    }
  };

  const handleSnackClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100dvh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Tooltip title="Chat Settings">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="New Chat">
            <IconButton
              color="inherit"
              aria-label="new_chat"
              edge="start"
              onClick={onNew}
              sx={{ mr: 2 }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" noWrap component="div">
            Ketr-Chat
          </Typography>
        </Toolbar>
      </AppBar>

      <Offset />

      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Box
          component="nav"
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={window.document.body}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            sx={{
              display: 'block',
              '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}
            slotProps={{
              root: {
                keepMounted: true, // Better open performance on mobile.
              },
            }}
          >
            <Toolbar />
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }} className="ChatBox" ref={conversationRef}>
          <Box className="Conversation" sx={{ flexGrow: 2, p: 1 }}>
            {conversation.map((message, index) => <Message key={index} message={message} />)}
            <div style={{ justifyContent: "center", display: "flex", paddingBottom: "0.5rem" }}>
              <PropagateLoader
                size="10px"
                loading={processing}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </Box>
          {/* <Box sx={{ mt: "-1rem", ml: "0.25rem", fontSize: "0.6rem", color: "darkgrey", position: "sticky" }}>Context used: {Math.round(100 * contextStatus.context_used / contextStatus.max_context)}% {contextStatus.context_used}/{contextStatus.max_context}</Box> */}
          <Box className="Query" sx={{ display: "flex", flexDirection: "row", p: 1 }}>
            <TextField
              variant="outlined"
              disabled={processing}
              autoFocus
              fullWidth
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Enter your question..."
              id="QueryInput"
            />
            <AccordionActions>
              <Tooltip title="Send">
                <Button sx={{ m: 0 }} variant="contained" onClick={sendQuery}><SendIcon /></Button>
              </Tooltip>
            </AccordionActions>
          </Box>
        </Box>
      </Box>


      <Snackbar open={snackOpen} autoHideDuration={(snackSeverity === "success" || snackSeverity === "info") ? 1500 : 6000} onClose={handleSnackClose}>
        <Alert
          onClose={handleSnackClose}
          severity={snackSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box >
  );
};

export default App;